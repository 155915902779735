export const work = {
  'technical': {
    1: { title: 'The Hanged Fox', thumbnail: 'hanged-fox/thumb.jpg',
      content: {
        1: { type: 'img', device: 'none', content: 'hanged-fox/hero.png' },
        2: { type: 'p', content: '<strong>Demo:</strong> <a href="http://the-hanged-fox.brie.work/" target="_blank">Link</a> <br /> <strong>Role:</strong> Development, Design <br /> <strong>Tech:</strong> React, Words API <br /> <strong>Artwork:</strong> <a href="http://www.freepik.com" target="_blank">Freepik</a> <br /> <strong>Client:</strong> Personal' },
        3: { type: 'p', content: '<em>Mobile hangman app developed using React and React Hooks.</em>' },
        4: { type: 'img', device: 'mobile', content: 'hanged-fox/01.png' },
        5: { type: 'img', device: 'mobile', content: 'hanged-fox/02.png' },
        6: { type: 'img', device: 'mobile', content: 'hanged-fox/03.png' },
        7: { type: 'img', device: 'mobile', content: 'hanged-fox/04.png' },
        8: { type: 'img', device: 'mobile', content: 'hanged-fox/05.png' },
      }
    },
    2: { title: 'Mini-Moose Asteroid Game', thumbnail: 'mini-moose/thumb.jpg',
      content: {
        1: { type: 'img', device: 'none', content: 'mini-moose/hero.png' },
        2: { type: 'p', content: '<strong>Demo: </strong><a href="http://mini-moose.brie.work" target="_blank">Link</a><br /> <strong>Role:</strong> Development, Design <br /> <strong>Tech:</strong> React<br /> <strong>Artwork:</strong> Jhonen Vasquez, Various sources <br /> <strong>Client:</strong> Personal<br />' },
        3: { type: 'p', content: '<em>Tap game developed using React and React Hooks.</em>' },
        4: { type: 'img', device: 'mobile', content: 'mini-moose/01.png' },
        5: { type: 'img', device: 'mobile', content: 'mini-moose/02.png' },
        6: { type: 'img', device: 'mobile', content: 'mini-moose/03.png' },
      }
    },
    3: { title: 'Connec+ions', thumbnail: 'connections/thumb.gif',
      content: {
        1: { type: 'img', device: 'none', content: 'connections/hero.jpg' },
        2: { type: 'p', content: '<strong>Demo: </strong><a href="http://connections.mna.co/" target="_blank">Link</a><br /> <strong>Role:</strong> Ideation, Development <br /> <strong>Tech:</strong> React <br /> <strong>Artwork:</strong> Christian Chavez, Emily Martin <br /> <strong>Client:</strong> minds + assembly' },
        3: { type: 'p', content: '<em>An interactive valentine’s day site for mind + assembly’s friends and clients.</em>' },
        4: { type: 'img', device: 'desktop', content: 'connections/01.png' },
        5: { type: 'img', device: 'desktop', content: 'connections/02.png' },
        6: { type: 'img', device: 'desktop', content: 'connections/03.png' },
        7: { type: 'img', device: 'desktop', content: 'connections/04.png' },
        8: { type: 'img', device: 'desktop', content: 'connections/05.png' },
        9: { type: 'img', device: 'none', content: 'connections/06.gif' },
      }
    },
    4: { title: 'Crosby’s Fortune-Telling Snow Globe', thumbnail: 'crosby/thumb.gif',
      content: {
        1: { type: 'img', device: 'none', content: 'crosby/hero.png' },
        2: { type: 'p', content: '<strong>Demo: </strong><a href="https://seethefuture.mna.co/Friend" target="_blank">Link</a><br /> <strong>Role:</strong> Ideation, Development <br /> <strong>Tech:</strong> React <br /> <strong>Artwork:</strong> Christian Chavez, Emily Martin <br /> <strong>Client:</strong> minds + assembly' },
        3: { type: 'p', content: '<em>An interactive holiday card for mind + assembly’s friends and clients.</em>' },
        4: { type: 'img', device: 'desktop', content: 'crosby/01.png' },
        5: { type: 'img', device: 'desktop', content: 'crosby/02.png' },
        6: { type: 'img', device: 'desktop', content: 'crosby/03.png' },
        7: { type: 'img', device: 'desktop', content: 'crosby/04.png' },
        8: { type: 'img', device: 'desktop', content: 'crosby/05.png' },
        9: { type: 'img', device: 'mobile', content: 'crosby/06.png' },
      }
    },
    5: { title: 'Gir’s Endless Adventure', thumbnail: 'endless/thumb.jpg',
      content: {
        1: { type: 'img', device: 'none', content: 'endless/hero.png' },
        2: { type: 'p', content: '<strong>Demo: </strong><a href="http://endless-adventure.brie.work/" target="_blank">Link</a><br /> <strong>Role:</strong> Creative, Development <br /> <strong>Tech:</strong> Vanilla JS <br /> <strong>Artwork:</strong> Jhonen Vasquez, Various sources <br /> <strong>Client:</strong> Personal' },
        3: { type: 'p', content: '<em>An interactive holiday card for mind + assembly’s friends and clients.</em>' },
        4: { type: 'img', device: 'desktop', content: 'endless/01.png' },
        5: { type: 'img', device: 'desktop', content: 'endless/02.png' },
        6: { type: 'img', device: 'desktop', content: 'endless/03.png' },
        7: { type: 'img', device: 'desktop', content: 'endless/04.png' },
        8: { type: 'img', device: 'desktop', content: 'endless/05.png' },
      }
    },
  },
  'creative': {
    1: { title: 'General Mills: Retro Cereal', thumbnail: 'gmrc/thumb.jpg',
      content: {
        1: { type: 'img', device: 'none', content: 'gmrc/hero.png' },
        2: { type: 'p', content: '<strong>Role:</strong> Pitch, Creative Direction, Design<br/><strong>Developer:</strong> Eric Kollegger<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> General Mills' },
        3: { type: 'p', content: 'Teaming up with General Mills for their limited edition Retro Cereal release, we brought gaming back to the arcade! Scanning the back of the specially-marked boxes unlocked a 3D gaming arcade panel. Users could play straight off of the packaging, or in full-screen mode.' },
        4: { type: 'img', device: 'none', content: 'gmrc/01.png' },
        5: { type: 'img', device: 'none', content: 'gmrc/02.png' },
        6: { type: 'p', content: 'Download Blippar and scan one of the boxes to view the experience!' },
      }
    },
    2: { title: 'Kashi: Stretch Island', thumbnail: 'kashi/thumb.jpg',
      content: {
        1: { type: 'img', device: 'none', content: 'kashi/hero.png' },
        2: { type: 'p', content: '<strong>Role:</strong> Pitch, Creative Direction, Design<br/><strong>Developer:</strong> Mike Burnett<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> Kashi' },
        3: { type: 'img', device: 'none', content: 'kashi/01.png' },
        4: { type: 'p', content: 'Kashi’s rebranding of it’s healthy fruit strips line—Stretch Island—was a delight to work with. We created an AR experience that allowed kids to play a fruit-fish catching game straight off the packaging!' },
        5: { type: 'img', device: 'none', content: 'kashi/02.png' },
        6: { type: 'p', content: 'Download Blippar and scan the package on the left to view the experience!' },
      }
    },
    3: { title: 'Campbell’s Spider-Man', thumbnail: 'campbells/thumb.jpg',
      content: {
        1: { type: 'img', device: 'none', content: 'campbells/hero.jpg' },
        2: { type: 'p', content: '<strong>Role:</strong> Pitch, Creative Direction, Design<br/><strong>Developer:</strong> Mike Burnett<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> Campbell’s Soup' },
        3: { type: 'p', content: 'In a partnership with Marvel, Campbell’s released limited-edition Spider-Man soup cans!' },
        4: { type: 'p', content: 'We brought the web-slinger to life straight-off the can, swinging into a 360° gyroscope game. Three different can designs, featuring three different villains that you can face: Jack O’Lantern, Venom, and Beetle.' },
        5: { type: 'img', device: 'none', content: 'campbells/01.png' },
        6: { type: 'img', device: 'none', content: 'campbells/02.png' },
        7: { type: 'p', content: 'Scan the can on the left with the Blippar app to view the experience!' },
        8: { type: 'img', device: 'none', content: 'campbells/03.jpg' },
      }
    },
    4: { title: 'AOL & TMNT', thumbnail: 'aol/thumb.png',
      content: {
        1: { type: 'img', device: 'none', content: 'aol/hero.png' },
        2: { type: 'p', content: '<strong>Role:</strong> Creative Direction, Design<br/><strong>Designer:</strong>Jae Salavarrieta<strong><br />Developer:</strong> Eric Kollegger<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> AOL' },
        3: { type: 'img', device: 'none', content: 'aol/01.png' },
        4: { type: 'p', content: 'Staging a takeover on X-Box systems, Jae Salavarieta and team brought the Teenage Mutant Ninja Turtles to life in a full 3D AR experience! Users received codes to unlock avatars, could save the feature dates, and pre-order tickets. The Turtle’s truck is also interactive—throwing flames and shooting manhole covers at the tap of a finger!' },
        6: { type: 'img', device: 'none', content: 'aol/02.jpg' },
      }
    },
    5: { title: 'Intel', thumbnail: 'intel/thumb.png',
      content: {
        1: { type: 'img', device: 'none', content: 'intel/hero.jpg' },
        2: { type: 'p', content: '<strong>Role:</strong> Pitch, Creative Direction, Design<br/><strong>Developer:</strong> Allan Tagino<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> Intel' },
        3: { type: 'p', content: 'Working with an agency and Intel, we created an immersive 360° gyroscopic experience. Jump inside the machine and let Intel show you just what makes its processors the leading edge in technology!' },
        4: { type: 'img', device: 'none', content: 'intel/01.jpg' },
      }
    },
    6: { title: 'Heineken: Desperados', thumbnail: 'heineken/thumb.jpg',
      content: {
        1: { type: 'img', device: 'none', content: 'heineken/hero.jpg' },
        2: { type: 'p', content: '<strong>Role:</strong> Pitch, Creative Direction, Design<br/><strong>Developer:</strong>Eric Kollegger<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> Heineken' },
        3: { type: 'p', content: 'To help Heineken promote their Desperados beer, we teamed together and created an experience where users could enter in an instant-win competition by submitting a picture of themselves completing a randomly-generated dare to their favorite social media platform.' },
        4: { type: 'img', device: 'none', content: 'heineken/01.png' },
        5: { type: 'img', device: 'none', content: 'heineken/02.png' },
        6: { type: 'img', device: 'none', content: 'heineken/03.png' },
        7: { type: 'img', device: 'none', content: 'heineken/04.png' },
      }
    },
    7: { title: 'PepsiCo: LATAM', thumbnail: 'pepsico/thumb.jpg',
      content: {
        1: { type: 'img', device: 'none', content: 'pepsico/hero.jpg' },
        2: { type: 'p', content: '<strong>Role:</strong> Pitch, Creative Direction, Design<br/><strong>3D Development:</strong> Allan Tagino<br /><strong>Developer:</strong> Rahul Mamtani<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> PepsiCo' },
        3: { type: 'p', content: 'Working with PepsiCo across Latin America, we executed an instant-win promotion straight off of the Pepsi cup. Users witnessed specially-designed cups peel away, revealing the instant-win result. From here, users could also design their own cup, and see it come to life—all in AR!' },
        4: { type: 'img', device: 'none', content: 'pepsico/01.png' },
      }
    },
    8: { title: 'General Mills: Lucky Charms', thumbnail: 'general-mills/thumb.jpg',
      content: {
        1: { type: 'img', device: 'none', content: 'general-mills/hero.jpg' },
        2: { type: 'p', content: '<strong>Role:</strong> Pitch, Creative Direction, Design<br/><strong>3D Development:</strong> Allan Tagino<br /><strong>Developer:</strong> Shane Tan<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> General Mills' },
        3: { type: 'video', youtubeId: '84FR7b-b9l8' },
        4: { type: 'img', device: 'none', content: 'general-mills/01.png' },
        5: { type: 'p', content: 'To celebrate General MIlls Lucky Charms brand featuring new limited-edition Swirled Diamond Charms, we teamed up to bring the rear panel to life with an audio-visual AR game!' },
        6: { type: 'p', content: 'Kids interact with the 3D pieces, replicating audio keys in sequence alongside an encouraging Lucky and beat-boxing Mountain Matt!' },
        7: { type: 'img', device: 'none', content: 'general-mills/02.png' },
        8: { type: 'p', content: 'Download Blippar and scan the box on the left to view the experience!' },
        9: { type: 'img', device: 'none', content: 'general-mills/03.jpg' },
      }
    },
    9: { title: 'Residence Inn: The Mix', thumbnail: 'inn/thumb.png',
      content: {
        1: { type: 'img', device: 'none', content: 'inn/hero.png' },
        2: { type: 'p', content: '<strong>Role:</strong> Pitch, Creative Direction, Design<br/> <strong>3D Development:</strong> Allan Tagino<br /> <strong>Developer:</strong> Patrick Hiller<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> Residence Inn / Mariott' },
        3: { type: 'p', content: 'We pushed the technical team with Residence Inn, delivering a multiplayer game that scaled across the country. At special mixers organized by Mariott and featured in Residence Inn establishments, guests were handed scannable coasters, launching a 3D menu where they could access beer pairings, selfie picture frames, and a multiplayer trivia game!' },
        4: { type: 'video', youtubeId: 'yVcKd-4mw2I' },
        5: { type: 'img', device: 'none', content: 'inn/01.png' },
        6: { type: 'img', device: 'none', content: 'inn/02.png' },
        7: { type: 'img', device: 'none', content: 'inn/03.png' },
        8: { type: 'img', device: 'none', content: 'inn/04.png' },
        9: { type: 'img', device: 'none', content: 'inn/05.png' },
        10: { type: 'img', device: 'none', content: 'inn/06.png' },
        11: { type: 'img', device: 'none', content: 'inn/07.png' },
        12: { type: 'p', content: 'Download Blippar and scan the coaster on the left to view the experience!' },
        13: { type: 'img', device: 'none', content: 'inn/08.png' },
      }
    },
    10: { title: 'EA: Dragon Age', thumbnail: 'ea/thumb.jpg',
      content: {
        1: { type: 'img', device: 'none', content: 'ea/hero.jpg' },
        2: { type: 'p', content: '<strong>Role:</strong> Pitch, Creative Direction, Design<br/><strong>Developer:</strong> Eric Kollegger<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> Electronic Arts' },
        3: { type: 'p', content: 'To help promote the new installment of Dragon Age, EA ran a sweepstakes for the fall of ‘14. We brought the advert to life, providing a way for users to enter into the sweeps, take a branded selfie, and watch the Dragon Age INQUISITION trailer.' },
        4: { type: 'p', content: 'Download the Blippar app and scan the image below to view the experience!' },
        5: { type: 'img', device: 'none', content: 'ea/01.jpg' },
      }
    },
    11: { title: 'General Mills: Trix', thumbnail: 'gm-trix/thumb.png',
      content: {
        1: { type: 'img', device: 'none', content: 'gm-trix/hero.png' },
        2: { type: 'p', content: '<strong>Role:</strong> Pitch, Creative Direction, Design<br/><strong>Developer:</strong> Eric Kollegger<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> General Mills' },
        3: { type: 'p', content: 'General Mills released a special box for their Trix brand—featuring fair-inspired games—and we brought them to life straight off the rear panel! Kids could tap to play the Fruity Taste-O-Meter or Trix Toss.' },
        4: { type: 'p', content: 'Download Blippar and scan the Trix box to view the experience!' },
        5: { type: 'img', device: 'none', content: 'gm-trix/01.jpg' },
      }
    },
    12: { title: 'Heinz', thumbnail: 'heinz/thumb.png',
      content: {
        1: { type: 'img', device: 'none', content: 'heinz/hero.jpg' },
        2: { type: 'p', content: '<strong>Role:</strong> Pitch, Creative Direction, Design<br/><strong>Developer:</strong> Patrick Hiller<br/><strong>Agency:</strong> Blippar<br/><strong>Client:</strong> Heinz' },
        3: { type: 'img', device: 'none', content: 'heinz/01.png' },
        4: { type: 'p', content: 'We teamed up with Heinz to support the US Military in this execution. Each social share through the experience was matched with a monetary contribution from Heinz. Users also could access videos, recipes, and even play an online game!' },
        6: { type: 'img', device: 'none', content: 'heinz/02.png' },
        7: { type: 'p', content: 'Download Blippar and scan the label on the left to view the experience!' },
        8: { type: 'img', device: 'none', content: 'heinz/03.png' },
      }
    },
  }
};
