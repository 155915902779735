import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

import Home from '../../Components/Home/Home';
import About from '../../Components/About/About';
import WorkContainer from '../WorkContainer/WorkContainer';
import Navigation from '../../Components/Navigation/Navigation';

function App() {
  const [page, setPage] = useState('home');
  const [projectOpen, setProjectOpen] = useState(false);

  function openProject() {
    setProjectOpen(true);
  };

  function closeProject() {
    setProjectOpen(false);
  };

  return (
    <div className="App">
      <Router>
        <Navigation location={page} changePage={setPage} closeProject={closeProject} projectOpen={projectOpen} />
        
        <Switch>
          <Route path='/work' render={() => <WorkContainer openProject={openProject} projectOpen={projectOpen} changePage={setPage} />} />
          <Route path='/about' render={() => <About changePage={setPage} />} />     
          <Route path='/' component={Home} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
