import React, { useEffect } from 'react';
import './Home.css';

export default function Home() {
  const body = document.body;

  useEffect(() => {
    body.classList.add('prevent-scroll');

    return () => {
      body.classList.remove('prevent-scroll');
    };
  }, [body]);

  return (
    <div className='home-container'>
      <h1>Brie</h1>
      <p>
        full stack engineer.<br />
        lead creative.<br />
        your favorite cheese.
      </p>
    </div>
  );
};