import React, { useState, useEffect, useRef } from 'react';
import './WorkContainer.css';

import Work from '../../Components/Work/Work';
import {work as rawWorkData} from '../../data';

export default function WorkContainer(props) {
  const imagePath = './images/work/';
  const [work, setWork] = useState();
  const technical = useRef();
  const creative = useRef();
  const technicalIndicator = useRef();
  const creativeIndicator = useRef();
  const [creativeWorkData, setCreativeWorkData] = useState([]);
  const [technicalWorkData, setTechnicalWorkData] = useState([]);

  function openCreative(e) {
    props.openProject();
    setWork(creativeWorkData.find(v => v.title === e.target.id));
  };
  
  function openTechnical(e) {
    props.openProject();
    setWork(technicalWorkData.find(v => v.title === e.target.id));
  };

  useEffect(() => {
    props.changePage('work');
    if (!props.projectOpen) setWork();
    let temporaryArray1 = [];
    let temporaryArray2 = [];

    for (const k in rawWorkData.creative) {
      temporaryArray1.push(rawWorkData.creative[k]);
    };

    setCreativeWorkData([...temporaryArray1]);
    
    for (const k in rawWorkData.technical) {
      temporaryArray2.push(rawWorkData.technical[k]);
    };

    setTechnicalWorkData([...temporaryArray2]);

  }, [props.projectOpen, props]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function openCloseSection(e) {
    if (e === 'technical') {
      technical.current.classList.toggle('close');
      technicalIndicator.current.innerHTML === '–' ? technicalIndicator.current.innerHTML = '+' : technicalIndicator.current.innerHTML = '–';
    };
    if (e === 'creative') {
      creative.current.classList.toggle('close');
      creativeIndicator.current.innerHTML === '–' ? creativeIndicator.current.innerHTML = '+' : creativeIndicator.current.innerHTML = '–';
    };
  };

  return (
    <div className='work-container-container'>
      <h1>work</h1>

      <div className='section-divider'>
        <div className='invisible-btn' onClick={() => openCloseSection('technical')}></div>
        <span>technical projects</span>
        <span ref={technicalIndicator}>–</span>
      </div>
      <div className='section' ref={technical}>
        {technicalWorkData.map(content => {
          return <div className='project' key={content.title + 'div'} id={content.title} onClick={openTechnical} style={{backgroundImage: `url(${imagePath + content.thumbnail})`}}></div>
        })}
      </div>

      <div className='section-divider'>
        <div className='invisible-btn' onClick={() => openCloseSection('creative')}></div>
        <span>creative projects</span>
        <span ref={creativeIndicator}>–</span>
      </div>
      <div className='section' ref={creative}>
        {creativeWorkData.map(content => {
            return <div className='project' key={content.title + 'div'} id={content.title} onClick={openCreative} style={{backgroundImage: `url(${imagePath + content.thumbnail})`}}></div>
          })}
      </div>

      {work ? <Work data={work} close={() => setWork()} /> : null}
    </div>
  );
};