import React, {useEffect} from 'react';
import './About.css';

export default function About(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
    props.changePage('about');
  }, [props]);

  return (
    <div className='about-container'>
      <h1>about</h1>
      <p>
        I’m a Full-Stack developer, Senior Art Director, and Creative Lead with extensive experience in the Augmented Reality space over the past 5-years. My experience in digital and print extends even further, designing and programming for both web and print.
      </p>

      <h2>cv</h2>

      <p>
        <strong className='cv-head'>TECHNICAL PROJECTS</strong><br />
        <strong>Octothoke</strong> - <a href='https://github.com/briecodes/mod5_frontend' target='_blank' rel='noopener noreferrer'>Github Frontend</a> | <a href='https://github.com/briecodes/mod5_backend' target='_blank' rel='noopener noreferrer'>Github Backend</a><br />
        Karaoke app allowing users to sign up, host/join karaoke events, and submit songs to sing.
      </p>
      <ul>
        <li>
          Developed frontend with React / Redux and backend with a Rails API.
        </li>
        <li>
          Incorporated YouTube API for video search.
        </li>
        <li>
          Custom-designed responsive frontend.
        </li>
      </ul>
      

      <p>
        <strong>Off Broadway</strong> - <a href='https://github.com/briecodes/off_broadway_frontend' target='_blank' rel='noopener noreferrer'>Github Frontend</a> | <a href='https://github.com/briecodes/off_broadway_backend' target='_blank' rel='noopener noreferrer'>Github Backend</a><br />
        Scripting app allowing users to create scripts and watch them “acted out” in a 2D theater.
      </p>
      <ul>
        <li>
          Developed frontend with React and backend with a Rails API.<br />
        </li>
        <li>
          Utilized Semantic to partially define styling.
        </li>
      </ul>

      <p>
        <strong>Gir’s Endless Adventure</strong> - <a href='https://github.com/briecodes/girs-endless-adventure' target='_blank' rel='noopener noreferrer'>Github Frontend</a> | <a href='https://github.com/briecodes/girs-endless-adventure-backend' target='_blank' rel='noopener noreferrer'>Github Backend</a><br />
        Endless side-scroller game, avoiding flying bees in space with three stages.
      </p>
      <ul>
        <li>
          Developed Vanilla javascript frontend with a Rails API backend.
        </li>
        <li>
          Defined High Score submission to Rails API.
        </li>
      </ul>

      <p>
        <strong className='cv-head'>TECHNICAL SKILLS</strong><br />
        Ruby, Rails, JavaScript, React, Redux, SQL, Adobe Creative Suite (Photoshop, Illustrator, InDesign, Premiere), Final Cut, Flash, Quark, Coda, ExpressionEngine.
      </p>

      <p>
        <strong className='cv-head'>EMPLOYMENT HISTORY</strong><br />
        <strong>Disney Streaming Services</strong>, New York, NY<br />
        Software Engineer, October 2020 — Present<br />
      </p>
      <ul>
        <li>
          React and Typescript development for the <em>marketing landing</em> and <em>splash</em> pages at disneyplus.com
        </li>
      </ul>
      
        <p>
          <strong>minds + assembly</strong>, New York, NY<br />
          Senior Developer, Nov 2018 – October 2020<br />
          Developer Internship, Aug 2018 – Nov 2018
        </p>

        <ul>
          <li>
            Developed multiple sites and apps for client-facing initiatives, including games.
          </li>
          <li>
            Led interactive panel and iVA projects for multiple conventions utilizing vanilla JS, React, and Gatsby frameworks.
          </li>
          <li>
            Developed responsive emails using Foundation &amp; MJML frameworks.
          </li>
          <li>
            Coded an interactive panel for the 2018 ACAAI Scientific Meeting convention utilizing Gatsby.js.
          </li>
          <li>
            Created documentation for code standards.
          </li>
        </ul>
      <p>
        <strong>Blippar</strong>, New York, NY<br />
        Product Creative Lead, August 2017 - March 2018<br />
        Creative Lead, August 2015 - August 2017<br />
        US Design Lead, March 2013 - August 2015
      </p>

      <ul>
        <li>
          Oversaw pitches and projects in NY, Singapore, and the U.K.; helped secure over $500K in ARDP revenue in 6 months.
        </li>
        <li>
          Supervised a team of designers and 3D artists, working closely between developers, business developers, and clients.
        </li>
        <li>
          Led projects from conception and pitch to execution.
        </li>
      </ul>


      <p>
        <strong>One Long House</strong>, New York, NY<br />
        Senior Designer &amp; Front-End Developer, April 2009 - February 2013
      </p>

      <ul>
        <li>
          Co-founded a creative cooperative of designers, programmers, and artists serving both small and large businesses globally.
        </li>
        <li>
          Served as lead designer and front-end developer (JavaScript, CSS, HTML, ExpressionEngine) across multiple projects.
        </li>
      </ul>


      <p>
        <strong>Rema Hort Mann Foundation</strong>, New York, NY<br />
        Multimedia Designer &amp; Developer,  June 2009 - October 2012
      </p>

      <ul>
        <li>
          Served as multimedia designer and front-end developer for a non-profit organization providing grants to artists.
        </li>
        <li>
          Developed online submission and voting portal for artists seeking grants and the foundation to award them.
        </li>
      </ul>

      <p>
        <strong>Green Team Advertising</strong>, New York, NY<br />
        Designer, January 2008- February 2009<br />
        Intern, Fall 2008
      </p>

      <ul>
        <li>
          Developed branding of multiple tourist agencies such as Visit Scotland and Puerto Vallarta.
        </li>
        <li>
          Programmed and animated web banners in Flash.
        </li>
        <li>
          Designed and programmed company's literary website.
        </li>
        <li>
          Prepared and delivered prepress files for print in magazines, billboards, and signage.
        </li>
      </ul>


      <p>
        <strong>Envoy Design Co.</strong>, New York, NY<br />
        Freelance Designer, April 2007 - February 2008
      </p>

      <ul>
        <li>
          Designed and prepared materials for print such as marketing kits and event booth posters for multiple clients.
        </li>
      </ul>
      

      <p>
        <strong>Print FX</strong>, New York, NY<br />
        Part-time Designer &amp; Studio Monitor, 2004 - 2007
      </p>

      <ul>
        <li>
          Developed Print FXís identity and internal print materials.
        </li>
        <li>
          Assisted full-time staff with digital printing.
        </li>
        <li>
          Coordinated with customers seeking print and digital services.
        </li>
      </ul>


      <p>
        <strong>iVillage Parenting Network</strong>, New York NY<br />
        Design Intern, Summer 2006
      </p>
        
      <ul>
        <li>
          Designed external promotional materials such as media kits, cards, gifts, and advertising.
        </li>
        <li>
          Handled digital informational design for internal decks and various internal printed materials.
        </li>
      </ul>

      
      <p>
        <strong>Tutor Time</strong>, Long Island, NY<br />
        Teaching Assistant and Child Care Provider, Summer 2003
      </p>
        
      <ul>
        <li>
          Developed educational activities for children ages 5-12.
        </li>
      </ul>

      
      <p>
        <strong className='cv-head'>FREELANCE 2003–PRESENT</strong><br />
        <strong>Cobble Hill Playschool</strong>, Brooklyn, NY<br />
        Redesigned and programmed website with ExpressionEngine (EE) as the content management system.
      </p>

      <p>
        <strong>Women’s Voices Now</strong>, New York, NY<br />
        Designed and programmed a content-managed website, with additional programming by Marc Vose.
      </p>

      <p>
        <strong>g-Think</strong>, New York, NY<br />
        Redesigned and programmed issue-based website with a new content management system, EE.
      </p>

      <p>
        <strong>Took An Axe Productions</strong>, New York, NY<br />
        Designed and programmed website with a content management system EE for an off-broadway show.
      </p>

      <p>
        <strong>Dangerous Minds</strong>, Los Angeles, CA<br />
        Designed and programmed website using the EE CMS.
      </p>

      <p>
        <strong>The Station Quartet</strong>, Los Angeles, CA<br />
        Designed and programmed interactive flash website.
      </p>

      <p>
        <strong>Jack Walsh</strong>, Brooklyn, NY<br />
        Designed and programmed portfolio website.
      </p>

      <p>
        <strong>Isotope Films</strong>, New York, NY<br />
        Designed and programmed company website using an open-source CMS.
      </p>

      <p>
        <strong>Caroline Berti</strong>, Long Island, NY<br />
        Designed, programmed and launched flash portfolio website.
      </p>

      <p>
        <strong>Mammoth Advertising</strong>, Brooklyn, NY<br />
        Designed title treatments and DVD cover comps.
      </p>

      <p>
        <strong>Miriam Cabessa</strong>, Brooklyn, NY<br />
        Maintained and updated website, including encoding video for web use.
      </p>

      <p>
        <strong>Campus Anti-War Network</strong>, FIT Chapter, New York, NY<br />
        Designed multiple posters and flyers for various campaigns.
      </p>

      <p>
        <strong>International Socialist Organization, Borough of Manhattan Community College (BMCC) Chapter</strong>, New York, NY<br />
        Designed a myriad of flyers and posters for their many activist campaigns.
      </p>

      <p>
        <strong>Long Island Professional Sculptors &amp; Supporters (LIPSS)</strong>, Long Island, NY<br />
        Re-designed and programmed arts group website.
      </p>

      <p>
        <strong className='cv-head'>EDUCATION</strong><br />
        <strong>Flatiron School</strong> - New York, NY - 2018<br />
        Full Stack Web Development (Ruby, Rails, JavaScript, React, Redux) Immersive Program<br />
      </p>

      <p>
        <strong>Fashion Institute of Technology (FIT)</strong> - New York, NY<br />
        Bachelor of Fine Arts: Graphic Design Major, Advertising Minor, 2007<br />
        Associates in Applied Science: Communication Design Major, 2005
      </p>
    </div>
  );
};
