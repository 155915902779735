import React, {useEffect, useState, useRef} from 'react';
import './Work.css';

import xIcon from '../../Assets/Images/x-white.svg';
import phone from '../../Assets/Images/mobile-template.png';
import phoneR from '../../Assets/Images/mobile-template-r.png';
import desktop from '../../Assets/Images/desktop-template.png';

export default function Work(props) {
  const body = document.body;
  const container = useRef();
  const imagePath = './images/work/';
  const [cleanedData, setCleanedData] = useState([]);
  
  useEffect(() => {
    body.classList.add('prevent-scroll');

    container.current.classList.add('on');
    let temporaryArray = [];
    for (const k in props.data.content) {
      temporaryArray.push(props.data.content[k]);
    };
    setCleanedData([...temporaryArray]);

    return () => {
      body.classList.remove('prevent-scroll');
    };
  }, [props.data.content, body]);

  function closeProject() {
    container.current.classList.remove('on');
    body.classList.remove('prevent-scroll');
    
    window.setTimeout(() => {
      props.close();
    }, 510);
  };

  return (
    <div className='work-container' ref={container}>
      <div className='close-btn' onClick={closeProject}>
        <img src={xIcon} alt='Close' />
      </div>
      <h1>{props.data.title}</h1>

      <div className='work-structure'>
        {cleanedData.map(k => {
          let element;

          if (k.type === 'img') {
            if (k.device === 'mobile') element = <div key={k.content + ' div'} className='mobile-device' ><img className='phone-graphic' src={phone} alt='iPhone' key={k.content + ' phone'} /><img className='mobile-artwork' src={imagePath + k.content} alt={k.content} key={k.content} /></div>;
            if (k.device === 'desktop') element = <div key={k.content + ' div'} className='desktop-device' ><img className='laptop-graphic' src={desktop} alt='MacBook Pro' key={k.content + ' desktop'} /><img className='desktop-artwork' src={imagePath + k.content} alt={k.content} key={k.content} /></div>;
            if (k.device === 'none') element = <img src={imagePath + k.content} alt={k.content} key={k.content} />;
          } else if (k.type === 'p') {
            element = React.createElement('p', {key: k.content, dangerouslySetInnerHTML: {__html: k.content} });
          } else if (k.type === 'video') {
            element = <div key={k.content + ' div'} className='mobile-device-video' >
                        <img className='phone-graphic-video' src={phoneR} alt='iPhone' key={k.content + ' phone'} />
                        <iframe className='video-iFrame' title={k.youtubeId} width="560" height="315" src={`https://www.youtube.com/embed/${k.youtubeId}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
          };

          return element;
        })}
      </div>
      
    </div>
  );
};